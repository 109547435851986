import React, { Component } from 'react';
import './styles/background.scss';
import BackgroundIMG from './img/background.jpg';
class Background extends Component {

  constructor(){
    super();
    this.state ={
        show:false
    }
  }

  componentDidMount(){
    const {show} = this.props;
    setTimeout(()=>{
        this.setState({
            show:show
        })
    },100)
    
  }

  render() {
      const {show} = this.state;
      let className = 'mask';
      if(show){
        className += ' mask_before'
      }
    return (
      <div className="background">
        <img src={BackgroundIMG} alt=""/>
        <div className={className}></div>
      </div>
    );
  }
}

export default Background;
