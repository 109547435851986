import React, { Component } from 'react';
import './styles/background.scss';
import BackgroundIMG from './img/background.jpg';
class BackgroundM extends Component {

  constructor(){
    super();
    this.state ={
        show:false
    }
  }

  componentDidMount(){
    const {show} = this.props;
    setTimeout(()=>{
        this.setState({
            show:show
        })
    },100)
  }

  render() {
    console.log(this.props.activeIndex)
    const {show} = this.state;
    const { activeIndex,steps } = this.props;
    let className = 'mask';
    let styles = {};
    if(show){
        className += ' mask_before';
        let backgroundSize = 600;
        styles = {
            left:-backgroundSize/steps*(activeIndex)
        }
    }
    
    return (
      <div className="background_m_box">
        <div className="background_m_content">
            <img src={BackgroundIMG} style={styles} alt=""/>
            <div className={className}></div>
        </div>
      </div>
    );
  }
}

export default BackgroundM;
