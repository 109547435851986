import React, { Component } from 'react';
import './styles/welcome.scss'

class Welcome extends Component {

  constructor(){
    super();
    this.state ={
        boxHeight:'0px',
        baseStyles:{},
        textNormalColor:true,
        text:''
    }
  }

  componentDidMount(){
    let me = this;
    window.addEventListener('resize', me.resizeListener);
    me.setState({
      text:window.indexConfig.welcome
    })
    me.resizeListener();
    setTimeout(()=>{me.welcomeLoaded()},window.indexConfig.showItemsTime);
  }
  componentWillUnmount() {
      window.removeEventListener('resize', this.resizeListener);
  }

  resizeListener =()=>{
    let scorllHeight = document.documentElement.clientHeight;
    this.setState({
      boxHeight: scorllHeight+'px'
    })
  }
  welcomeLoaded =()=>{
    let me = this;
    const {device} = this.props;
    me.setState({
        baseStyles:{
            width:'180px',
            height:'30px',
            lineHeight:'30px',
            fontSize:'30px',
            left:'-300px',
            top:'50px',
            background:'transparent'
        },
        textNormalColor:false
    },()=>{
      if(!!device){

      }else{
        setTimeout(()=>{
          me.setState({
            baseStyles:{
              width:'180px',
              height:'30px',
              lineHeight:'30px',
              fontSize:'30px',
              left:'0px',
              top:'50px',
              background:'transparent'
            },
            text:window.indexConfig.indexTitle
          })
        },1000)
      }
    })
  }
  
  render() {
    let { boxHeight ,baseStyles,textNormalColor,text} = this.state;
    let styles = JSON.parse(JSON.stringify(baseStyles));
    if(!!styles['lineHeight']){

    }else{
        styles['lineHeight'] = boxHeight;
    }
    return (
      <div className="welcome" style={styles}>
        <p className={textNormalColor?'text':''}>{text}</p>
      </div>
    );
  }
}

export default Welcome;
