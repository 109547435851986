import React, { Component } from 'react';
import Block from './block.jsx';
import './styles/itemsBox.scss';
class ItemsBoxM extends Component {

  constructor(){
    super();
    this.state ={
      
    }
  }

  componentDidMount(){

  }
  loadBlocks=()=>{

    const { items } = this.props;
    let blocks = [];
    for(let i = 0;i<items.length;i++){
        blocks.push(<Block 
            key={'block_'+i} 
            config = {items[i]}
        />)
    }
    return blocks;
  }

  render() {
    let scorllHeight = document.documentElement.clientHeight;
    let srorllWidth = document.documentElement.clientWidth;
    return (
      <div className="items_box_m" style={{width:srorllWidth-100,paddingRight:srorllWidth-90-240,height:scorllHeight-130}}>
        <div className = "items_box_m_content" >
            {this.loadBlocks()}
        </div>
      </div>
    );
  }
}

export default ItemsBoxM;
