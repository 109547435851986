// 苏ICP备2022005082号
import React, { Component } from 'react';
import './footer.scss';

class Footer extends Component {

  render() {
    return (
      <div className="footer">
        Liangxiaotian 版权所有 <a href="https://beian.miit.gov.cn" target="_blank" rel="noreferrer">苏ICP备2022005082号</a>
      </div>
    );
  }
}

export default Footer;