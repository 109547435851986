import React, { Component } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import ItemsBoxM from './itemsBox_m.jsx';

import 'swiper/css';

import './styles/content.scss';


class ContentM extends Component {

  constructor(){
    super();
    this.state ={
        activeIndex:0,
        slidesPerView:1
    }
  }

  componentDidMount(){
    // let me = this;
    // let sw = new Swiper('.swiper-container', {
    //     direction : 'horizontal',
    //     slidesOffsetBefore : -50,
    //     slidesOffsetAfter : 50,
    //     on: {
    //         slideChangeTransitionStart: function(){
    //             me.props.setActiveBg(sw.activeIndex)
    //         }
    //     }
    // });
  }

  initSwiper = () =>{
    let itemsConfig = window.projectItemsConfig;
    let items = [];
    for(let i = 0;i<itemsConfig.length;i++){
        let last = false;
        if(i===itemsConfig.length-1){
            last = true;
        }
        items.push(<SwiperSlide key={'swiper-slide-'+i} config = {itemsConfig[i]}>
            <div className="content_title">{itemsConfig[i]['className']}</div><ItemsBoxM items = {itemsConfig[i]['items']}/>
        </SwiperSlide>)
    }
    return items;
  }

  render() {
    let itemsConfig = window.projectItemsConfig;
    const {slidesPerView} = this.state;
    return (
        <div className='swiper_container'>
            <Swiper
                className='swiper_box'
                slidesPerView={slidesPerView}
                onTransitionStart={(s) => this.props.setActiveBg(s.realIndex) }
                onSwiper={(swiper) => ''}
                // slidesOffsetBefore={-50}
                // slidesOffsetAfter={50} 
            >
                {itemsConfig.map((item,index)=>
                    <SwiperSlide
                      key={index}>
                      <div className='content_title'>{item['className']}</div>
                      <ItemsBoxM items = {itemsConfig[index]['items']}/>
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    );
  }
}

export default ContentM;
