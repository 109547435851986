import React, { Component } from 'react';
import Welcome from './welcome.jsx';
import ContentM from './content_m.jsx';
import BackgroundM from './background_m.jsx';
import './styles/home.scss';


class HomeM extends Component {

  constructor(){
    super();
    this.state ={
      showItem:false,
      activeIndex:0
    }
  }

  componentDidMount(){
    let me = this;
    setTimeout(()=>{
      me.setState({
        showItem:true
      })
    },window.indexConfig.showItemsTime+200)
  }

  setActiveBg=(index)=>{
    this.setState({
        activeIndex:index
    })
  }

  render() {
    const { showItem,activeIndex } = this.state;
    const { device } = this.props;
    return (
      <div className="home">
        <Welcome device = {device}/>
        {showItem?<ContentM show={showItem} setActiveBg = {this.setActiveBg}/>:''}
        {showItem?<BackgroundM show={showItem} activeIndex = {activeIndex} steps = {window.projectItemsConfig.length}/>:''}
      </div>
    );
  }
}

export default HomeM;
