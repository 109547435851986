import React, { Component } from 'react';
import Block from './block.jsx';
import './styles/itemsBox.scss';

class ItemCol extends Component{
    constructor(){
        super();
        this.state = {
            
        }
    }

    componentWillMount(){

    }

    render(){
        const {colItems} = this.props;
        return (
            <div className="item_col">
            {/* <div className = "block block_active block_sm"> */}
            
            {/* </div> */}
                { colItems }
            </div>
        )
    }
}


class ItemsBox extends Component {

  constructor(){
    super();
    this.state ={
        con:''
    }
  }

  componentDidMount(){
    let me = this;
    window.addEventListener('resize', me.resizeListener);
    
    me.resizeListener();
  }
  componentWillUnmount() {
      window.removeEventListener('resize', this.resizeListener);
  }

  resizeListener =()=>{
    let me = this;
    setTimeout(()=>{
        me.setState({
            con:this.loadBlocks()
        },()=>{
            const {last,setOverflowWidth} = me.props;
            if(last){
                let els = document.getElementsByClassName('items_box');
                let width = 160;
                for(let i= 0;i<els.length;i++){
                    width += parseFloat(els[i].style.width)
                }
                setOverflowWidth(width);
            }
        })
    },500)
  }


  loadBlocks=()=>{
    const {items,contentHeight} = this.props;
    let itemsConfig = items['items'];
    let blocks = [];
    let allCols = [];
    let baseHeight = 0;
    let add = true;
    for(let i=0;i<itemsConfig.length;i++){
        
        if(itemsConfig[i]['size'] === 'lg'){
            baseHeight+=230;
            add = true;
        }else if(itemsConfig[i]['size'] === 'md'){
            baseHeight+=110;
            add = true;
        }else{
            if(add){
                baseHeight+=110;
                add = false;
            }else{
                add = true;
            }
            
        }
        
        if(baseHeight>contentHeight-145){
            allCols.push(<ItemCol key={'colItems_'+i} colItems = {blocks}/>);
            if(itemsConfig[i]['size'] === 'lg'){
                baseHeight=230;
            }else{
                baseHeight=110;
            }
            blocks = [];
        }
            blocks.push(
                <Block 
                    key={'block_'+i} 
                    config = {itemsConfig[i]}
                />
            )
        
        // console.log('继续加模块')
        
    }
    allCols.push(<ItemCol key={'colItems_last'} colItems = {blocks}/>);
    // sm的高度110
    // md的高度110
    // lg得高度是230
    // setOverflowWidth(allCols.length*240);
    // console.log(allCols)
    return allCols;
  }
  render() {
    const {items} = this.props;
    let {con} = this.state;
    return (
      <div className="items_box" style={{width:(con.length)*240}}>
        <div className="item_title">{items['className']}</div>
        {con}
      </div>
    );
  }
}

export default ItemsBox;
