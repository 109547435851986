import React, { Component } from 'react';
import Welcome from './welcome.jsx';
import Content from './content.jsx';
import Background from './background.jsx';
import './styles/home.scss';


class Home extends Component {

  constructor(){
    super();
    this.state ={
      showItem:false
    }
  }

  componentDidMount(){
    let me = this;
    setTimeout(()=>{
      me.setState({
        showItem:true
      })
    },window.indexConfig.showItemsTime+200)
  }

  render() {
    const { showItem } = this.state;
    return (
      <div className="home">
        <Welcome/>
        {showItem?<Content show={showItem}/>:''}
        {showItem?<Background show={showItem}/>:''}
      </div>
    );
  }
}

export default Home;
