import React, { Component } from 'react';
import ItemsBox from './itemsBox.jsx';
import './styles/content.scss'


class Content extends Component {

  constructor(){
    super();
    this.state ={
      top:0,
      height:0,
      left: '-100%',
    }
  }

  componentDidMount(){

    
    let me = this;
    window.addEventListener('resize', me.resizeListener);
    
    me.resizeListener();
  }

  initContent=()=>{
    let me = this;
    setTimeout(()=>{
      me.setState({
        left:0
      })
    },1000)
  }

  loadContent = ()=>{

    let itemsConfig = window.projectItemsConfig;
    let items = [];
    const { height } = this.state;
    for(let i = 0;i<itemsConfig.length;i++){
      let last = false;
      if(itemsConfig.length-1 === i){
        last = true;
      }
      items.push(
        <ItemsBox key={'itemBox_'+i} items={itemsConfig[i]} contentHeight = {height} last={last} setOverflowWidth={this.setOverflowWidth}/>
      )
    }
    return items;
  }

  componentWillUnmount() {
      window.removeEventListener('resize', this.resizeListener);
  }

  resizeListener =()=>{
    let scorllHeight = document.documentElement.clientHeight;
    let me = this;
    me.setState({
      top:(scorllHeight/3)/2,
      height:scorllHeight/3*2,
    },()=>{
      me.initContent()
    })
  }
  
  setOverflowWidth =(width)=>{
    this.setState({
      contentOverflowWidth:width
    })
  }

  render() {
    const { top,height,left,contentOverflowWidth } = this.state;
    let contentStyle = {
      top:top,
      height:height,
      left:left
    }
    let bStyle = {};
    if(contentOverflowWidth!==0){
      bStyle = {
        width:contentOverflowWidth
      }
    }
    return (
      <div className="content" style={contentStyle}>
        <div className="content_overflow" style={bStyle}>
          {this.loadContent()}
        </div>
      </div>
    );
  }
}

export default Content;
