import React, { Component } from 'react';
import './styles/block.scss';

// 名片前
class BusinessCardBefore extends Component{

  render(){
    const { config } = this.props;
    const base = window.indexConfig;
    return(
      <div className="business_card_before">
          <div className="header_img">
            <img src={config['thumbnail']} alt=""/>
          </div>
          <div className="name_box">
            <span>
              {config['personalInfo']['fristName']}
            </span>
            <span  className="f">
              {config['personalInfo']['lastName']} 
            </span>  
          </div>
          {/* <div className="info_row">{base['sex']}{config['personalInfo']['sex']}</div> */}
          {/* <div className="info_row">{base['region']}{config['personalInfo']['region']}</div> */}
          {/* <div className="info_row"> </div> */}
          <div className="info_row">{base['tel']}{config['personalInfo']['tel']}</div>
          <div className="info_row">{base['email']}{config['personalInfo']['email']}</div>
          
          
      </div>
    )
  }
}
// 名片后
class BusinessCardAfter extends Component {

  render(){
    const { config } = this.props;
    const base = window.indexConfig;
    return (
        <div className="business_card_after">
          {base['introduction']}{config['personalInfo']['introduction']}
        </div>
    )
  }
}

class BlockBefore extends Component{
  render(){
    const { config } = this.props;
    let style = {}
    if(!!config['color']){
      style = {
        color:config['color']
      }
    }
    return (
      <button className="block_C_before">
          <img src={config['icon']} alt=""/>
          <div className="block_name_box" style={style}>
              {config['name']}
          </div>
      </button>
    )
  }
}

class BlockAfter extends Component{
  render(){
    const { config } = this.props;
    let style = {}
    if(!!config['thumbnail']){
      style = {
        backgroundImage:'url('+config['thumbnail']+')'
      }
    }
    
    return (
      <div className="block_C_after" style={style}>

          {config['introduction']}
      </div>
    )
  }
}


// 小方块
class Block extends Component {

  constructor(){
    super();
    this.state ={
        blockType:'',
        show:false,
        ts:false
    }
  }

  componentDidMount(){
    const { config } = this.props;
    let me = this;
    let type = config['size'];
    me.setState({
        blockType:type
    })
    setTimeout(()=>{
        me.setState({
            show:true
        })

        setInterval(()=>{me.anInterval()},me.tsTime())
    },me.showTime())
  }

  showTime = ()=>{
    return (Math.random()*(2-1)+1)*(1000)
  }

  tsTime = () =>{
    return (Math.random()*(10-5)+5)*(1000)
  }

  anInterval = () =>{
    let { ts } = this.state;
    this.setState({
        ts:!ts
    })
  }


  render() {
    const { blockType,ts,show } = this.state;
    const { config } = this.props;

    let {background,href,businessCard} = config;
    let classNames = 'block block_'+blockType;
    if(show){
        classNames+= ' block_show';
    }
    if(ts){
        classNames+= ' block_an';
    }
    return (
        <a href={href} className={classNames} target="_blank" rel="noreferrer">
            <div className="block_before" style={{background:background}} >
              {businessCard?<BusinessCardBefore
                config = {config}
              />:<BlockBefore
                config = {config}
              />}
            </div>
            <div className="block_after" style={{background:background}} >
                {businessCard?<BusinessCardAfter
                  config = {config}
              />:<BlockAfter
                config = {config}
              />}
            </div>
        </a>
    );
  }
}

export default Block;
