import React, { Component } from 'react';
import './App.scss';
import Home from './components/home';
import HomeM from './components/home_m';
import Footer from './layout/footer';


class App extends Component {

  constructor(){
    super();
    this.state ={

    }
  }

  componentDidMount(){
    
  }
  browserRedirect = ()=> {
    let sUserAgent = navigator.userAgent.toLowerCase();
    let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    let bIsMidp = sUserAgent.match(/midp/i) == "midp";
    let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    let bIsAndroid = sUserAgent.match(/android/i) == "android";
    let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
      return 'phone';
    } else {
      return 'pc';
    }
  }

  render() {
    let device = this.browserRedirect();
    return (
      <div className="App">
        { device == 'pc'?<Home/>:<HomeM device = {device}/>}
        <Footer/>
      </div>
    );
  }
}

export default App;
